/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-16 15:13:43
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-16 15:51:54
 */
import SectionTitle from './sectionTitle.vue'
export default SectionTitle
