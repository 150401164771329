/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-16 15:14:17
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-16 19:53:35
 */
export default {
  name: 'SectionTitle',
  props: {
    tooltip: {
      type: String,
      default: ''
    }
  }
}
